import React, { useEffect, useState } from "react";
import axios from "axios";
import Carousel from "../carousel/Carousel";
// import { Quote } from "../imagepath";
import { mission } from "../imagepath";
import { vision } from "../imagepath";
import { about } from "../imagepath";
import { process } from "../imagepath";

import {
  CheckRound2,
  Course01Icon,
  Course02Icon,
  Course03Icon,
  Course04Icon,
  Pattern01,
  Time,
} from "../imagepath";
import { TopCategories2 } from "./slider/topCategories";

import AOS from "aos";
import "aos/dist/aos.css";
import CountUp from "react-countup";
import "./home.css";
import { Helmet } from "react-helmet";

export const Home = () => {
  // const [showDaata] = useState(false);

  useEffect(() => {
    document.body.className = "home-three";

    return () => {
      document.body.className = "";
    };
  }, []);

  useEffect(() => {
    AOS.init({ duration: 1200, once: true });
  }, []);

  const [userCount, setUserCount] = useState({
    institutes: 0,
    educators: 0,
    parents: 0,
    students: 0,
  });

  const getAllDataUserCount = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/users/count`
      );
      const data = response?.data.data;
      setUserCount(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getAllDataUserCount();
  }, []);

  return (
    <>
      <Helmet>
        <Helmet>
          <title>Special Education Platform - edeekshaam</title>
          <meta
            name="description"
            content="edeekshaam is a platform dedicated to connecting special children, parents, educators, and schools."
          />
          <link rel="canonical" href="https://www.edeekshaam.in" />
        </Helmet>
      </Helmet>

      <Carousel />
      <section className="section  home-three-course mt-5">
        <div className="container">
          <div className="course-widget-three">
            <div className="row">
              <div className="col-lg-3 col-md-6 d-flex" data-aos="fade-up">
                <div className="course-details-three">
                  <div className="align-items-center">
                    <div className="course-count-three course-count ms-0">
                      <div className="course-img">
                        <img className="img-fluid" src={Course02Icon} alt="" />
                      </div>
                      <div className="course-content-three">
                        <h4 className="text-yellow">
                          <span className="d-flex">
                            <CountUp
                              start={0}
                              end={userCount.institutes}
                              delay={1}
                            />
                            {/* + */}
                          </span>
                        </h4>
                        <p>Institutions/Schools</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 d-flex" data-aos="fade-up">
                <div className="course-details-three">
                  <div className="align-items-center">
                    <div className="course-count-three course-count ms-0">
                      <div className="course-img">
                        <img className="img-fluid" src={Course01Icon} alt="" />
                      </div>

                      <div className="course-content-three">
                        <h4 className="text-blue">
                          <span className="d-flex">
                            <CountUp
                              start={0}
                              end={userCount.educators}
                              delay={1}
                              duration={4}
                            />
                            {/* + */}
                          </span>
                        </h4>
                        <p>Educators</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 d-flex" data-aos="fade-up">
                <div className="course-details-three">
                  <div className="align-items-center">
                    <div className="course-count-three course-count ms-0">
                      <div className="course-img">
                        <img className="img-fluid" src={Course03Icon} alt="" />
                      </div>
                      <div className="course-content-three">
                        <h4 className="text-info">
                          <span className="d-flex">
                            <CountUp
                              start={0}
                              end={userCount?.parents}
                              delay={1}
                              duration={5}
                            />
                            {/* + */}
                          </span>
                        </h4>
                        <p>Parents</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 d-flex" data-aos="fade-up">
                <div className="course-details-three">
                  <div className="align-items-center">
                    <div className="course-count-three course-count ms-0">
                      <div className="course-img">
                        <img className="img-fluid" src={Course04Icon} alt="" />
                      </div>
                      <div className="course-content-three">
                        <h4 className="text-info">
                          <span className="d-flex">
                            <CountUp
                              start={0}
                              end={userCount?.students}
                              delay={1}
                              duration={5}
                            />
                            {/* + */}
                          </span>
                        </h4>
                        <p>Students</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Online Courses */}

      {/* Master Skills Career */}
      <section className="master-skill-three">
        <div className="master-three-vector">
          <img className="ellipse-right img-fluid" src={Pattern01} alt="" />
        </div>
        <div className="container">
          <div className="row">
            <div
              className="col-xl-6 col-lg-6 col-md-12 d-flex justify-content-center align-items-center"
              data-aos="fade-right"
            >
              <div className="master-three-images">
                <div className="master-three-left">
                  <img
                    className="img-fluid"
                    src={about}
                    alt="image-banner"
                    title="image-banner"
                    crossOrigin="anonymous"
                  />
                </div>
              </div>
            </div>

            <div className="col-xl-6 col-lg-6 col-md-12" data-aos="fade-left">
              <div className="home-three-head" data-aos="fade-up">
                <h2>About ε-Deekshaam</h2>
              </div>

              <h1 className="text-blue mb-4 ct-h1">
                Inclusive Education is our first priority
              </h1>

              {/* <div className="py-4">
                <img className="quote home3Slide" alt="" src={Quote} />
              </div> */}
              <div className="home-three-content" data-aos="fade-up">
                <p>
                  &ldquo;The highest education is that which does not merely
                  gives information but brings our life in harmony with all
                  existence.&rdquo;
                </p>
                <strong>~ Rabindranath Tagore</strong>
              </div>
              <div className="career-group aos" data-aos="fade-up">
                <div className="row">
                  <div className="col-lg-6 col-md-6 d-flex">
                    <div className="certified-group blur-border d-flex">
                      <div className="get-certified">
                        <div className="mission-vision mb-3">
                          <img className="" src={mission} alt="" />
                        </div>
                        <h5>Our Mission</h5>
                        <p className="m-0">
                          Our mission is to provide an adaptive assistive system
                          to help children with intellectual disabilities to
                          learn and succeed. We aim to offer a supportive and
                          inclusive environment where children learn
                          meaningfully, develop essential skills, and gain
                          confidence in their abilities.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 d-flex">
                    <div className="certified-group blur-border d-flex">
                      <div className="get-certified">
                        <div className="mission-vision mb-3">
                          <img className="" src={vision} alt="" />
                        </div>
                        <h5>Our Vision</h5>
                        <p className="m-0">
                          Our Vision is that every child with an intellectual
                          disability thrives and accomplishes their goals. Our
                          adaptive assistive system will help children with
                          intellectual disabilities overcome hurdles and reach
                          their full potential.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Master Skills Career */}

      {/* Process */}
      <section className="testimonial-three">
        <div className="container">
          {/* Learn Anything */}
          <div className="learn-anything border-0">
            <div className="row align-items-center">
              <div
                className="col-xl-6 col-lg-6 col-md-6 col-12"
                data-aos="fade-up"
              >
                <div className="award-three-content-two">
                  <div className="award-list-info">
                    <div className="mb-3">
                      <img className="img-fluid" src={Time} alt="certified" />
                    </div>
                    <div className="award-list-content">
                      <h5 className="text-white">How does it work?</h5>
                      <h2>Our Process is easy to understand</h2>
                      <p>
                        The needs and abilities of each child with intellectual
                        disability are assessed. The assessment helps to
                        determine the topics to be taught. Based on the
                        assessment ε-Deekshaam helps the teacher to customize
                        the learning plan for each child.
                      </p>
                    </div>
                  </div>

                  <div className="award-list d-flex">
                    <span className="award-icon">
                      <img src={CheckRound2} alt="" className="" />
                    </span>
                    <p>
                      E-Deekshaam&apos;s interactive lessons, videos, and games
                      are tailored to the child&apos;s needs.{" "}
                    </p>
                  </div>
                  <div className="award-list d-flex">
                    <span className="award-icon">
                      <img src={CheckRound2} alt="" className="" />
                    </span>
                    <p>
                      E-Deekshaam adjusts the child&apos;s education plan and
                      content as they progress.
                    </p>
                  </div>

                  <div className="award-list d-flex">
                    <span className="award-icon">
                      <img src={CheckRound2} alt="" className="" />
                    </span>
                    <p>
                      The system can track children&apos;s progress for
                      teachers, parents, and Rehab professionals.
                    </p>
                  </div>
                  <div className="award-list d-flex">
                    <span className="award-icon">
                      <img src={CheckRound2} alt="" className="" />
                    </span>
                    <p>
                      E-Deekshaam reports and analyses the child&apos;s
                      strengths, weaknesses, and progress. These reports help
                      teachers and staff make data-driven decisions. Progress
                      tracking and assessment enable individualized
                      instructional strategies and support for the child.
                    </p>
                  </div>
                </div>
              </div>

              <div
                className="col-xl-6 col-lg-6 col-md-6 col-12 d-flex justify-content-center"
                data-aos="fade-right"
              >
                <div className="award-three-images-two master-three-left">
                  <img
                    className="img-fluid"
                    src={process}
                    alt="image-banner"
                    title="image-banner"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Favorite Course */}
      <section className="home-three-favourite mt-5 pt-5 pb-5">
        <div className="container">
          <div className="row">
            <div className="container">
              <div
                className="home-three-head section-header-title"
                data-aos="fade-up"
              >
                <div className="row align-items-center d-flex justify-content-between">
                  <div className="col-lg-8 col-sm-12">
                    <h2>ε-Deekshaam Statistics</h2>
                  </div>
                </div>
              </div>
              <TopCategories2 />
            </div>
          </div>
          {/* Favorite Course */}
        </div>
      </section>
      {/* Favorite Course */}

      {/* Footer */}

      {/* Footer */}
    </>
  );
};
